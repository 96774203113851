




































import { Component, Vue } from 'vue-property-decorator';
import FullCalendar, {
  CalendarOptions,
  EventApi,
  DateSpanApi,
  DatePointApi,
} from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { INITIAL_EVENTS, createEventId } from '@/common';
import { PostReservationRequestDto } from '@/dto/reservation/post-reservation-request.dto';
import reservationService from '@/services/reservation.service';
import EventDto from '@/dto/reservation/event.dto';
import GetReservTimesResponseDto from '@/dto/reservation/get-reserv-times-response.dto';
// import krLocale from '@fullcalendar/core/locales/ko';
import { getBaseUrl } from '@/common/utils/base-url.util';
import {
  DevelopmentEnvironment,
  StagingEnvironment,
  ProductionEnvironment,
  EnvironmentType,
} from '../../../environments';
@Component({
  name: 'Calendar',
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
})
export default class Calendar extends Vue {
  private postReserationRequestDto = new PostReservationRequestDto();
  private eventDto: EventDto[] = [];
  private terms: GetReservTimesResponseDto[] = [];
  private selectedTime = '';
  private calendarOptions: CalendarOptions = {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin, // needed for dateClick
    ],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'today',
    },
    buttonText: {
      today: '오늘',
      day: '일',
      week: '주',
      month: '월',
    },
    initialView: 'dayGridMonth',
    // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    // editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: false,
    showNonCurrentDates: false,
    dateClick: this.handleDateSelect,
    // eventClick: this.handleEventClick,
    eventsSet: this.handleEvents,
    locale: 'ko',
    slotMinTime: '10:00:00',
    slotMaxTime: '19:00:00',
    allDaySlot: false,
    slotDuration: '01:00:00',

    //오늘 이전 배경색 분홍색으로
    eventSources: [{ url: `${getBaseUrl()}reservation/holidays` }],
    events: [
      {
        start: '1970-01-01',
        end: new Date().toISOString().slice(0, 10),
        // color: '#ff9f89',
        color: '#d0d0d0',
        display: 'background',
      },
    ],
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  private currentEvents: EventApi[] = [];
  handleWeekendsToggle() {
    this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
  }
  handleDateSelect(selectInfo: DatePointApi) {
    if (this.$moment(selectInfo.dateStr).isBefore(new Date())) {
      return false;
    }
    //주말 클릭안됨
    const isWeekend =
      selectInfo.date.getDay() === 6 || selectInfo.date.getDay() === 0;
    if (isWeekend) {
      return false;
    }
    //공휴일 클릭안됨
    const include = this.eventDto.some(e => e.start == selectInfo.dateStr);
    if (include) {
      return false;
    }
    this.postReserationRequestDto.reservationDate = selectInfo.date;
    reservationService.getTimeSlots(selectInfo.dateStr).subscribe(res => {
      this.terms = res.data;
    });
    this.selectedTime = '';
    this.$bvModal.show('select-time');
  }
  onClickTime(time: string) {
    this.selectedTime = time;
  }
  onTimeModalOk() {
    this.postReserationRequestDto.reservationTime = this.selectedTime;
    reservationService
      .postReservation(this.postReserationRequestDto)
      .subscribe(res => {
        if (res) {
          this.$router.push('/reservation/success');
        }
      });
  }
  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  created() {
    const code = sessionStorage.getItem('reservationCode');
    if (!code) {
      this.$router.push('/reservation');
    } else {
      this.postReserationRequestDto.reservationCode = code;
    }
    reservationService.getHoliday().subscribe(res => {
      this.eventDto = this.eventDto.concat(res.data);
    });
  }
}
